<template>
  <table class="reports_table text-left flex-1 h-full">
    <DeleteReportModalV2 :deleteReportObj="deleteReportObj"></DeleteReportModalV2>
    <DispatchReportModalV2 :dispatchReportId="dispatchReportObj"></DispatchReportModalV2>
    <ArchiveReportsModalV2 :archiveReportsObj="archiveReportsObj"></ArchiveReportsModalV2>
    <ActivityReportV2 ></ActivityReportV2>
    <thead class="text-[#757A8A] bg-[#F4F6FA] font-semibold text-[0.875rem] font-poppins">
    <tr>
      <td class="py-3 text-left pl-3 w-[20%]">Name</td>
      <td class="py-3 text-left w-[20%]">Email</td>
      <td class="py-3 text-center w-[20%]">Send Report</td>
      <td class="py-3 text-center w-[20%]">Created at</td>
      <td class="py-3 pr-3 text-right w-[12.5%]">Actions</td>
    </tr>
    </thead>
    <tbody v-if="getReportsLoaders.retrieve">
    <tr>
      <td colspan="6">
        <p style="padding: 10px;">
          <clip-loader
            :size="size"
            :color="color"
          ></clip-loader>
        </p>

      </td>
    </tr>
    </tbody>
    <tbody v-else-if="getReports.items.length === 0">
    <tr>
      <td colspan="9" class="">
        <p class="no-rows"><i class="fab fa-bandcamp" aria-hidden="true"></i>{{
            getFilteredMessage('reports', getReports.search, 'All Time', getReports.archiveStatus)
          }}</p>
      </td>
    </tr>
    </tbody>
    <tbody v-else-if="getReports.items.length > 0" class="font-poppins text-[#3C4549] text-[0.875rem]">
    <tr v-for="(report,index) in getReports.items" class="border-b border-[#ECEEF5] px-3">
      <td class="py-3 pl-3 w-[20%]">
        <div
          class=""
          :class="{'overlay_item archived':getReports.archiveStatus}"
        >
          <p>{{ report.name }}</p>
          <p>You have {{ report.brands.length }} brands and {{ report.campaigns.length }}
            campaigns...</p>
        </div>
      </td>
      <td class="py-3 text-left w-[20%]" >

        <div :class="{'overlay_item archived':getReports.archiveStatus}">
          <template v-if="report.emails.length === 1">
                              <span>
                                {{ report.emails[0] }}
                              </span>
          </template>
          <template v-else-if="report.emails.length > 1">
            {{ report.emails[0] }}

            <div class="view_more custom_tooltip">

                                <span class="view_text">
                                  View {{ report.emails.length - 1 }} more address
                                </span>
              <div class="tool_tip_box">
                <div class="inner_box">
                  <li
                    style="list-style: none; text-align: center;"
                    v-for="email in report.emails.slice(1)"
                  >{{ email }}
                  </li>
                </div>
              </div>

            </div>
          </template>
          <!--<span v-for="email in report.emails">{{email}}</span>-->
        </div>
      </td>
      <td class="py-3 text-center w-[20%]" >
        <div class="flex justify-center items-center" :class="{'overlay_item archived':getReports.archiveStatus}">
          <template v-if="report.daily">
                           <p>Daily</p>
          </template>
          <template v-if="report.weekly">
            <p v-if="report.daily && report.weekly">&nbsp;|&nbsp;</p>
            <p>Weekly</p>
          </template>
          <template v-if="report.monthly">
            <p v-if="report.weekly && report.monthly">&nbsp;|&nbsp;</p>
            <p>Monthly</p>
          </template>
        </div>
      </td>
      <td class="py-3 text-center w-[20%]" >
        <div
          class="actions"
        >
          <p>{{report.created_at | relativeCaptilize}}</p>
        </div>
      </td>
      <td class='py-3 pr-3 text-right w-[12.5%]'>
        <div
          class="flex justify-end items-center pr-3"
          v-if="report.archive"
        >
          <svg v-tooltip="'Unarchive'" @click.prevent="$bvModal.show('modal-archive-report'), archiveReportsObj = {reportId: report._id, index: index, status: false}"
               data-cy="b-archive" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none"
               class="w-5 h-5">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.04256 2.44727C2.74291 2.44727 2.5 2.69018 2.5 2.98983V6.60689C2.5 6.90654 2.74291 7.14945 3.04256 7.14945H3.94678V16.0094C3.94678 16.3091 4.18969 16.552 4.48934 16.552H17.5108C17.8104 16.552 18.0533 16.3091 18.0533 16.0094V7.14945H18.9576C19.2573 7.14945 19.5002 6.90654 19.5002 6.60689V2.98983C19.5002 2.69018 19.2573 2.44727 18.9576 2.44727H3.04256ZM17.5556 6.06433C17.5408 6.06312 17.5259 6.0625 17.5108 6.0625C17.4957 6.0625 17.4807 6.06312 17.4659 6.06433H4.5342C4.51941 6.06312 4.50444 6.0625 4.48934 6.0625C4.47423 6.0625 4.45927 6.06312 4.44447 6.06433H3.58512V3.53238H18.4151V6.06433H17.5556ZM5.0319 7.14945H16.9682V15.4669H5.0319V7.14945ZM7.45744 9.5C7.45744 9.20035 7.70035 8.95744 8 8.95744H14C14.2996 8.95744 14.5426 9.20035 14.5426 9.5C14.5426 9.79965 14.2996 10.0426 14 10.0426H8C7.70035 10.0426 7.45744 9.79965 7.45744 9.5ZM8 11.9574C7.70035 11.9574 7.45744 12.2004 7.45744 12.5C7.45744 12.7996 7.70035 13.0426 8 13.0426H14C14.2996 13.0426 14.5426 12.7996 14.5426 12.5C14.5426 12.2004 14.2996 11.9574 14 11.9574H8Z"
                  fill="#3C4549"></path>
            <path d="M20.5 1.5L1.5 18.5" stroke="#3C4549" stroke-width="1.08512" stroke-linecap="round"
                  stroke-linejoin="round"></path>
          </svg>
        </div>

        <div v-else class="float-right">
          <b-dropdown
            right
            ref="workspace_settings_dropdown"
            class="dropdown-menu-right hide_dropdown_caret default_style_dropdown"
            no-caret
          >
            <div class="dropdown_header justify-between hover:bg-[#EAF1FF] hover:text-[#2560D7] p-[0.5rem] rounded-full items-center gap-2.5 flex" slot="button-content">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <circle cx="10.0002" cy="9.99935" r="1.66667" transform="rotate(-90 10.0002 9.99935)" fill="#3C4549"/>
                <circle cx="3.33317" cy="9.99935" r="1.66667" transform="rotate(-90 3.33317 9.99935)" fill="#3C4549"/>
                <circle cx="16.6667" cy="9.99935" r="1.66667" transform="rotate(-90 16.6667 9.99935)" fill="#3C4549"/>
              </svg>
            </div>
            <transition name="fade">
              <ul class="w-full text-[#202324] font-poppins py-1 text-[0.875rem] shadow-lg bg-white rounded-tl-lg font-poppins rounded-bl-lg rounded-br-lg">
                <div @click.prevent="editReport(report)" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 16" fill="none">
                    <path d="M11.2271 1.95798C11.6004 1.58471 12.1067 1.375 12.6346 1.375C13.1625 1.375 13.6687 1.58471 14.042 1.95798C14.4153 2.33126 14.625 2.83753 14.625 3.36543C14.625 3.89332 14.4153 4.3996 14.042 4.77288L5.12819 13.6867L1.375 14.625L2.3133 10.8718L11.2271 1.95798Z" stroke="#3C4549" stroke-width="1.24219" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p>Edit</p>
                </div>
                <div @click.prevent="getEmailLog(report._id)" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                    <path d="M13.1177 6.85294L0.882381 1.5L5.47062 14.5L7.00003 8.38235L13.1177 6.85294Z" stroke="#3C4549" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div class="flex w-full justify-between">
                    <p>Activity</p>
                  </div>
                </div>
                <div @click.prevent="$bvModal.show('modal-dispatch-report-v2'), dispatchReportObj = report._id" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
                    <path d="M8.65916 2.04544H2.47734C2.06746 2.04544 1.67437 2.20826 1.38454 2.49809C1.09471 2.78792 0.931885 3.18102 0.931885 3.5909V15.9545C0.931885 16.3644 1.09471 16.7575 1.38454 17.0473C1.67437 17.3372 2.06746 17.5 2.47734 17.5H11.7501C12.1599 17.5 12.553 17.3372 12.8429 17.0473C13.1327 16.7575 13.2955 16.3644 13.2955 15.9545V6.6818" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.2046 11.5H4.02283" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.2046 9H4.02283" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.2046 14H4.02283" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.31818 6.5H4.77273H4" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11.9773 0.5H15.0682V3.59091" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.4319 5.13636L15.0682 0.5" stroke="#3C4549" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <p>Dispatch Report</p>
                </div>
                <div @click.prevent="$bvModal.show('modal-archive-report'), archiveReportsObj = {reportId: report._id, index: index, status: true}" class="cursor-pointer group hover:bg-[#F4F6FA] px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04244 0.94754C0.74279 0.94754 0.499878 1.19045 0.499878 1.4901V5.10716C0.499878 5.40681 0.74279 5.64972 1.04244 5.64972H1.94666V14.5097C1.94666 14.8093 2.18957 15.0523 2.48921 15.0523H15.5106C15.8103 15.0523 16.0532 14.8093 16.0532 14.5097V5.64972H16.9575C17.2572 5.64972 17.5001 5.40681 17.5001 5.10716V1.4901C17.5001 1.19045 17.2572 0.94754 16.9575 0.94754H1.04244ZM15.5555 4.5646C15.5407 4.56339 15.5257 4.56277 15.5106 4.56277C15.4955 4.56277 15.4806 4.56339 15.4658 4.5646H2.53408C2.51928 4.56339 2.50432 4.56277 2.48921 4.56277C2.47411 4.56277 2.45914 4.56339 2.44435 4.5646H1.585V2.03266H16.415V4.5646H15.5555ZM3.03177 5.64972H14.9681V13.9671H3.03177V5.64972ZM5.45732 8.00027C5.45732 7.70063 5.70023 7.45772 5.99988 7.45772H11.9999C12.2995 7.45772 12.5424 7.70063 12.5424 8.00027C12.5424 8.29992 12.2995 8.54283 11.9999 8.54283H5.99988C5.70023 8.54283 5.45732 8.29992 5.45732 8.00027ZM5.99988 10.4577C5.70023 10.4577 5.45732 10.7006 5.45732 11.0003C5.45732 11.2999 5.70023 11.5428 5.99988 11.5428H11.9999C12.2995 11.5428 12.5424 11.2999 12.5424 11.0003C12.5424 10.7006 12.2995 10.4577 11.9999 10.4577H5.99988Z" fill="#3C4549"/>
                  </svg>
                  <p>Archive</p>
                </div>
                <div @click.prevent="$bvModal.show('modal-delete-report-v2'), deleteReportObj = {reportId: report._id, index: index}"  class="cursor-pointer group hover:bg-[#F4F6FA] border-t px-[1rem] py-[0.75rem] flex gap-x-3 justify-start font-poppins items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 16 18" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.63083 1.72174C5.82519 1.52739 6.08879 1.4182 6.36365 1.4182H9.63638C9.91124 1.4182 10.1748 1.52739 10.3692 1.72174C10.5636 1.9161 10.6727 2.1797 10.6727 2.45456V3.49093H5.32729V2.45456C5.32729 2.1797 5.43647 1.9161 5.63083 1.72174ZM4.12729 3.49093V2.45456C4.12729 1.86144 4.3629 1.29262 4.7823 0.873216C5.2017 0.453817 5.77053 0.218201 6.36365 0.218201H9.63638C10.2295 0.218201 10.7983 0.453817 11.2177 0.873216C11.6371 1.29262 11.8727 1.86144 11.8727 2.45456V3.49093H13.7273H15.3636C15.695 3.49093 15.9636 3.75956 15.9636 4.09093C15.9636 4.4223 15.695 4.69093 15.3636 4.69093H14.3273V15.5455C14.3273 16.1386 14.0917 16.7074 13.6723 17.1268C13.2529 17.5462 12.684 17.7818 12.0909 17.7818H3.9091C3.31598 17.7818 2.74716 17.5462 2.32776 17.1268C1.90836 16.7074 1.67274 16.1386 1.67274 15.5455V4.69093H0.636377C0.305006 4.69093 0.036377 4.4223 0.036377 4.09093C0.036377 3.75956 0.305006 3.49093 0.636377 3.49093H2.27274H4.12729ZM2.87274 4.69093H4.72729H11.2727H13.1273V15.5455C13.1273 15.8203 13.0181 16.0839 12.8237 16.2783C12.6294 16.4726 12.3658 16.5818 12.0909 16.5818H3.9091C3.63424 16.5818 3.37064 16.4726 3.17628 16.2783C2.98193 16.0839 2.87274 15.8203 2.87274 15.5455V4.69093ZM6.36365 7.58184C6.69502 7.58184 6.96365 7.85047 6.96365 8.18184V13.0909C6.96365 13.4223 6.69502 13.6909 6.36365 13.6909C6.03228 13.6909 5.76365 13.4223 5.76365 13.0909V8.18184C5.76365 7.85047 6.03228 7.58184 6.36365 7.58184ZM10.2364 8.18184C10.2364 7.85047 9.96775 7.58184 9.63638 7.58184C9.30501 7.58184 9.03638 7.85047 9.03638 8.18184V13.0909C9.03638 13.4223 9.30501 13.6909 9.63638 13.6909C9.96775 13.6909 10.2364 13.4223 10.2364 13.0909V8.18184Z" fill="black"/>
                  </svg>
                  <p>Remove</p>
                </div>
              </ul>
            </transition>
          </b-dropdown>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { reportTypes } from '@/state/modules/mutation-types'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'

export default ({
  components: {
    DeleteReportModalV2:() => import('@/views/pages/reports/dialogs/DeleteReportModalV2.vue'),
    DispatchReportModalV2:() => import('@/views/pages/reports/dialogs/DispatchReportModalV2.vue'),
    ActivityReportV2:() => import('@/views/pages/reports/dialogs/ActivityReportV2.vue'),
    ArchiveReportsModalV2:() => import('@/views/pages/reports/dialogs/ArchiveReportsModalV2.vue')
  },
  data () {
    return {
      emailReports: [],
      dispatchReportObj: {},
      archiveReportsObj: {},
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR,
      allBrands: [],
      allCampaign: [],
      deleteReportObj: {}
    }
  },
  async created () {
    await this.fetchEmailReports()
    await this.fetchReports()
    this.allBrands = await this.fetchBrandList({ page: 1, isFetchAll: true })
    this.allCampaign = await this.fetchCampaignsList({ page: 1, isFetchAll: true })
  },
  computed: {
    ...mapGetters(['getReportsAdd', 'getReports', 'getReportsLoaders', 'getEmailReports'])
  },
  methods: {
    ...mapActions([
      'fetchReports',
      'deleteReport',
      'changeArchive',
      'fetchEmailReports',
      'dispatchManualReports',
      'fetchBrandList',
      'fetchCampaignsList'
    ]),
    showReportModal () {
      this.$store.commit(reportTypes.SET_REPORTS_ADD_DEFAULT_VALUE)
      // $('#addReport').modal('show')
      this.$bvModal.show('addReport')
    },
    editReport (report) {
      let reportAdd = this.getReportsAdd
      let campaigns = []
      let campaignsOptions = []
      let emailsList = []
      this.allCampaign.forEach(campaign => {
        campaignsOptions.push(campaign)
        if (report.campaigns.includes(campaign._id)) {
          campaigns.push(campaign)
        }
      })
      report.emails.forEach(item => {
        emailsList.push({ name: item })
      })
      this.$set(reportAdd, 'brands', this.allBrands.filter(item => report.brands.includes(item._id)))
      this.$set(reportAdd, 'campaigns', campaigns)
      this.$set(reportAdd, 'campaignsOptions', campaignsOptions)
      this.$set(reportAdd, 'name', report.name)
      this.$set(reportAdd, 'monthly', report.monthly)
      this.$set(reportAdd, 'weekly', report.weekly)
      this.$set(reportAdd, 'daily', report.daily)
      this.$set(reportAdd, '_id', report._id)
      this.$set(reportAdd, 'emails', emailsList)
      this.$set(reportAdd, 'emailsOption', emailsList)
      // $('#addReport').modal('show')
      this.$bvModal.show('addReportModalV2')
    },
    paginateReports (page) {
      // this.fetchReports(page)
    },
    showArchiveReports (status) {
      this.$store.commit(reportTypes.SET_REPORTS_ARCHIVE_STATUS, status)
      this.$store.commit(reportTypes.SET_REPORTS_SEARCH_VALUE, '')
      this.fetchReports()
    },
    getEmailLog (reportId) {
      this.emailReports = this.fetchEmailReports(reportId)
      this.$bvModal.show('email-reports-logs')
    },
    async dispatchReport (reportId) {
      let res = await this.dispatchManualReports(reportId)
      if (res) {
      }
    }
  },
  watch: {
    async 'getReports.search' (value) {
      if (value.length === 0) {
        await this.fetchReports(1)
      } else if (value && value.length >= 3) {
        await this.fetchReports(1)
      }
    }
  }
})
</script>
<style lang="less">
.reports_table {
  tbody {
    display: block;
    overflow-y: auto;
    height: calc(100% - 3.2rem);
    table-layout: fixed;
  }
  thead, thead tr, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}
.my_inner_dropdown {
  .dropdown-menu {
    left: -10px !important;
  }
}
</style>
